@font-face {
  font-family: gill-sans-nova;
  src: url("./assets/fonts/Monotype\ -\ Gill_Sans_Nova_Bold.ttf");
}
@font-face {
  font-family: GillSansNova-Bold;
  src: url("./assets/fonts/Monotype\ -\ Gill_Sans_Nova_Bold.ttf");
}

@font-face {
  font-family: gill-sans-nova-book;
  src: url("./assets/fonts/Gill_Sans_Nova_book.ttf");
}
@font-face {
  font-family: GillSansNova-Book;
  src: url("./assets/fonts/Gill_Sans_Nova_book.ttf");
}

@font-face {
  font-family: ALbert;
  src: url("./assets/fonts/AlbertSans-ExtraBold.otf");
}

:root {
  --white-color-fff8f0: #fff8f0;
  --orange-color-ffaa7b: #ffaa7b;
  --background-color-hover: #fc9157;
  --blue-color: #5494cf;
  --black-color-0a121a: #0a121a;
  --background-color: "none";
  --text-color: #fff8f0;

  /* --unnamed-color-03080d: #03080d; */
  --font-family-nova: gill-sans-nova;
  --font-family-nova-book: gill-sans-nova-book;
  --font-family-Albert: ALbert;
  --font-style-normal: normal;
  --font-weight-normal: normal;
  --font-weight-bold: bold;
  --font-size-18: 18px;
  --font-character-spacing-0: 0px;
  --font-line-spacing-28-799999237060547: 28.799999237060547px;
}

html[data-theme="orange"] {
  --background-color: #ffaa7b;
  --background-color-hover: #fc9157;
  --background: #fff8f0;
  --text-color: #ffaa7b;
}

html[data-theme="blue"] {
  --background-color: #5494cf;
  --background-color-hover: #448ccf;
  --text-color: #5494cf;
}

.App {
}

html,
body {
  overflow: hidden;
  font-family: var(--font-family-nova);
}
.circuit_block tspan,
.Patient_block tspan {
  font-family: var(--font-family-nova-book);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#logoPrincipale {
  fill: var(--background-color);
  transition: fill 0.5s ease-in;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.scrollbar-orange {
  --background-color: #ffaa7b;
  --background-color-hover: #fc9157;
  --background: #fff8f0;
  --text-color: #ffaa7b;
}
.scrollbar {
  scrollbar-color: var(--background-color) #f7f4ed;
  scrollbar-width: thin;
}

.scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.scrollbar::-webkit-scrollbar-track {
  /* border-radius: 100vh; */
  background: #f7f4ed;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: var(--background-color);
  /* border-radius: 100vh; */
  /* border: 1px solid #f6f7ed; */
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: var(--background-color-hover);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container-background {
  position: absolute;
  height: 100vh;
  width: 100vw;
}
.container-background-relative {
  position: relative;
  height: 100%;
  width: 100%;
  background: white;
}
.background-cold {
  position: absolute;
  height: 100%;
  width: 100%;
  clip-path: polygon(0 0, 49.5% 0, 49.5% 100%, 0% 100%);
  background: url(./assets/images/cold-background.jpg) no-repeat bottom center;
  background-size: cover;
}
.background-warm {
  height: 100%;
  width: 100%;
  left: 50%;
  clip-path: polygon(50.5% 0, 100% 0, 100% 100%, 50.5% 100%);
  background: url(./assets/images/warm-background.jpg) no-repeat bottom center;
  background-size: cover;
}

.container-info {
  display: inline-block;
  position: absolute;
  width: 100%;
  min-height: 100vh;
}

.container-info-mobile {
  /* display: inline-block; */
  /* position: absolute; */
  width: 100%;
  height: 49.5vh;
  background-color: red;
  background: url(./assets/images/cold-background.jpg) no-repeat bottom left;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Circuit_mobile {
  position: absolute;
  width: 145px;
  height: 145px;
  /* margin-left: 3px; */
  z-index: 1;
  background-color: #ffffffa8;
  border-radius: 50%;
  display: flex;
}

.Circuit_mobile svg {
  /* width: 91%; */
  /* margin-top: 1%; */
  /* margin-left: 1%; */
}

.Patient_mobile svg {
  /* width: 91%; */
}

@media (min-width: 768px) {
  .container-info {
    float: left;
    /*position: fixed;*/
    left: 0;
    top: 0;
    height: 100%;
    width: 49.5%;
  }
}

@media (max-width: 545px) {
  .contact h1 {
    margin-bottom: 0em !important ;
    font-size: 2.5em;
  }
}

.container-main {
  display: inline-block;
  position: absolute;
  width: 100%;
  min-height: 100vh;
  /* background: url(./assets/images/warm-background.jpg) no-repeat bottom right;
  background-size: cover; */
}

.container-main-mobile {
  /* display: inline-block; */
  /* position: absolute; */
  width: 100%;
  /* top: 51%; */
  height: 49.5vh;
  background: url(./assets/images/warm-background.jpg) no-repeat bottom left;

  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Patient_mobile {
  position: absolute;
  width: 145px;
  height: 145px;
  z-index: 1;
  background-color: #ffffffab;
  border-radius: 50%;
  display: flex;
}

@media (min-width: 768px) {
  .container-main {
    float: right;
    /*position: fixed;*/
    right: 0;
    top: 0;
    height: 100%;
    width: 49.5%;
  }
}

.First_block1 {
  height: 300px;
  position: absolute;
  bottom: 0%;
  right: -1%;
  background-color: white;
  transition: width 0.5s ease;
  /* width: 300px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.First_block1 svg,
.Second_block2 svg {
  /* margin: 5%; */
  cursor: pointer;
  width: 0;
}

.container-info:hover .First_block1,
.container-info:active .First_block1 {
  animation: 0.5s widthInleft both 0s;
  width: 300px;
  cursor: pointer;
}

.container-info:hover svg {
  width: 100%;
}

.container-info:hover .btn-container {
  width: 44%;
  /* height: 47%; */
  /* background: blue; */
}

.container-info:hover .First_block1 img {
  animation: 0.5s widthInleft both 0s;
}

.Second_block2 {
  /* border: black 1px solid; */
  height: 300px;
  /* width: 150px; */
  position: absolute;
  bottom: 0%;
  left: -1%;
  transition: width 0.5s ease;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-main:hover .Second_block2,
.container-main:active .Second_block2 {
  animation: 0.5s widthInRight both 0s;
  width: 300px;
  cursor: pointer;
}

.container-main:hover svg {
  width: 100%;
}

.container-main:hover .btn-container {
  width: 44%;
  /* height: 47%; */
}

@keyframes widthInRight {
  0% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }

  100% {
    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
  }
}

@-webkit-keyframes widthInRight {
  0% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }

  100% {
    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
  }
}

@keyframes widthInleft {
  0% {
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
  }

  100% {
    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
  }
}

@-webkit-keyframes widthInleft {
  0% {
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
  }

  100% {
    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
  }
}

.circuit {
  /* display: inline-block; */
  position: absolute;
  width: 100%;
  min-height: 100vh;
  /* background-color: red; */
  background: url(./assets/images/cold-background.jpg) no-repeat bottom left;
  background-size: cover;
  transition: background 1s ease-in;
}

.patient {
  /* display: inline-block; */
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background: url(./assets/images/warm-background.jpg) no-repeat bottom right;
  background-size: cover;
}

.Patient_block,
.circuit_block {
  /* border: 1px black solid; */
  width: 30%;
  /* height: 45%; */
  position: absolute;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  /* transition: animation 0.5s ease-in; */
  /* animation: 1.75s ToptoBottom ease-in 0s; */
}

.forrwardAnimation {
  animation: 0.5s ToptoBottom ease-in 0s;
}

.reversedAnimation {
  animation: 0.5s BottomtoTop both ease-out;
}

.reversedAnimation-mobile {
  animation: 0.75s Bottomtomiddle both ease-out;
}

.Patient_block .headerBlock,
.circuit_block .headerBlock {
  height: 4rem;
  /* width: 100%; */
  /* border: black solid 1px; */
}

.Patient_block div:nth-child(2),
.circuit_block div:nth-child(2) {
  display: flex;
  justify-content: center;
}

.Patient_block .Image,
.circuit_block .Image {
  /* width: 67%; */
  margin-bottom: 3%;
  /* z-index: 99; */
}

/* .Patient_block {
  border: 1px black solid;
  width: 30%;
  height: 40%;
  position: absolute;
  animation: 1.75s ToptoBottom ease-in 0s;
  background-color: white;
} */

@keyframes Bottomtomiddle {
  0% {
    top: 100%;
  }

  100% {
    top: 50%;
  }
}

@keyframes ToptoBottomTOP {
  0% {
    top: 0%;
  }

  100% {
    top: 100%;
  }
}

@-webkit-keyframes ToptoBottomTOP {
  0% {
    top: 0%;
  }

  100% {
    top: 100%;
  }
}

@keyframes ToptoBottom {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }

  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}

@-webkit-keyframes ToptoBottom {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }

  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}

@keyframes BottomtoTop {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  100% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
}

@-webkit-keyframes BottomtoTop {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  100% {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
}

.menuItem {
  font: var(--font-style-normal) normal 800 30px/32px var(--font-family-Albert);
  letter-spacing: var(--font-line-spacing-28-799999237060547);
  color: var(--text-color);
  text-align: left;
  /* font: normal normal 800 32px/32px ALbert; */
  letter-spacing: 0px;
  /* color: #fff8f0; */
  opacity: 1;
  text-decoration: none;
}

.menuItem_number {
  /* transform: matrix(0, -1, 1, 0, 0, 0); */
  /* UI Properties */
  font: var(--font-style-normal) normal 300 16px/32px var(--font-family-nova);
  letter-spacing: var(--font-character-spacing-0);
  color: var(--text-color);
  text-align: left;
  /* font: normal normal 300 16px/32px Gill Sans Nova; */
  letter-spacing: 0px;
  /* color: #fff8f0; */
  opacity: 1;
  margin-right: 1.7em;
  margin-bottom: -7px;
  transform: rotate(-90deg);
}

.circuit__scroll {
  /* border: 1px solid black; */
  width: 70px;
  height: 70px;
  position: absolute;
  bottom: 3%;
  left: 26%;
  /* background-color: red; */
}

.Circuit_sliderBlock {
  /* border: 1px solid black; */
  width: 67%;
  height: 100%;
  position: absolute;
  right: 0%;
  /* top: 100%; */
  /* background-color: red; */
  transition: top 0.5s ease-in;
}

.circuit__scroll .scroll {
  transition: rotate 0.5s ease-in, fill 1s ease-in;
  fill: var(--background-color);
  cursor: pointer;
  position: absolute;
  /* transition: fill 1s ease-in; */
}

.circuit__scroll .scroll {
  background: none;
}

/* .circuit__scroll>div:hover .scroll-button-arrow {
  fill: var(--background-color);

}

.circuit__scroll>div:hover .scroll-button {
  fill: white;

} */

.scroll_mobile .scroll-button {
  fill: var(--background-color);
}

.circuit__scroll > div:hover .scroll-button-text {
  fill: var(--background-color);
}

.scroll_mobile .scroll-button-text {
  fill: var(--background-color);
}

.circuit__scroll .scroll .scroll-button-arrow {
  fill: white;
}

.scroll_mobile .scroll-button-arrow {
  fill: white;
}

.circuit__scroll .scroll .scroll-button {
  fill: var(--background-color);
}

.circuit__scroll .scroll .scroll-button {
  fill: var(--background-color);
}

.circuit__scroll .scroll .scroll-button-text {
  fill: var(--background-color);
}

.animationHoverFill {
  animation: WIDTHCIRCLE 0.5s both ease;
}

.btn > div:hover::before {
  content: "";
  position: absolute;
  width: 170px;
  height: 175px;
  background: white;
  /* transition: all 0.5s ease; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  /* transition: all 1s ease; */
  animation: WIDTHCIRCLE 0.75s both ease;
}

.btn.blue > div:hover::before {
  /* top: 53%; */
  background: var(--blue-color);
  z-index: -1;
  /* animation: WIDTHCIRCLE2 .5s both ease; */
}

.btn.orange > div:hover::before {
  /* top: 53%; */
  background: var(--background-color-hover);
  z-index: -1;
}

@keyframes WIDTHCIRCLE {
  from {
    width: 0%;
    height: 0;
  }

  to {
    width: 170px;
    height: 175px;
  }
}

.Footer {
  background-color: var(--background-color);
}

.FooterLogo {
  max-width: 150px;
  width: 100%;
  /* background-color: var(--white-color-fff8f0); */
}

.logo {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  /* margin-bottom: 2em; */
}

.logo svg {
  width: 50%;
  margin-left: 6%;
}

.footerStyles .rightblock {
  display: flex;
  justify-content: space-between;
  width: 40%;
  color: var(--background-color);
}

article {
  line-height: 1.7;
  font-size: 0.95em;
}

.no-light-height {
  line-height: initial;
}

article section {
  font-family: var(--font-family-nova-book);
  font-size: medium;
}

article sup {
  font-size: 0.65em;
}

article > section {
  margin: 1em 0;
  clear: both;
}

article > section:first-child {
  margin-top: 0;
}

article > section:last-child {
  margin-bottom: 0;
  font-size: 11.5px;
}

article > figure {
  scale: 1;
  transform-origin: left;
  transition: scale 0.1s linear;
}
article > figure:hover {
  scale: 1.03;
}

article > section div {
  margin-left: 2em;
}

article > section div.half_to_right {
  width: 75%;
  position: relative;
  transform: translateX(33%);
  margin: 0;
}

article > section div.half_to_right p {
  margin: 0;
  font-size: 0.7em;
  line-height: 1.4;
}

article > section div.half_to_right br {
  display: block;
  margin: 4px 0;
}

.half_to_right svg {
  fill: var(--background-color) !important;
}

.cls-1 {
  fill: unset !important;
}

article > section p {
  margin: 0;
}

article > section ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
  font-size: 11pt;
}

article > section ul > li {
  /* padding-left: 1em;
  text-indent: -1em; */
}

article > section ul > li:before {
  content: "";
  background-color: var(--background-color);
  -webkit-mask: url("assets/images/bullettBottom.svg") no-repeat 50% 50%;
  mask: url("assets/images/bullettBottom.svg") no-repeat 50% 50%;
  padding-right: 0.4em;
  margin-right: 1.6em;
  font-weight: bold;
  color: var(--text-color);
  width: 0.1em;
  height: 0.5em;
  display: inline-block;
  -webkit-mask-size: contain;
}

.text-patient {
  fill: #0a121a;
}

.circle-patient {
  fill: #0a121a;
}

.male-patient {
  fill: #fff8f0;
}

.text-circuit {
  fill: #0a121a;
}

.btn-container:hover .text-circuit,
.btn-container:hover .text-patient {
  fill: #fff8f0;
}

.male-circuit {
  fill: #fff8f0;
}

.circle-circuit {
  fill: #0a121a;
}

.btn-container:hover .circle-circuit,
.btn-container:hover .circle-patient {
  fill: transparent;
}

.containerMenu {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 67vw;
  display: flex;
  flex-direction: column;
  background: black;
  opacity: 0.95;
  color: rgb(250, 250, 250);
  transition: height 0.75s ease 0s;
  z-index: 3;
  justify-content: space-between;
}

.containerMenu_block {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  margin-left: 10rem;
  align-items: flex-start;
}

.footerStyles {
  width: 75%;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-inline: auto;
}

.footerStyles h3 {
  font: var(--font-style-normal) normal 600 var(--font-size-18) / 25px
    var(--font-family-nova);
}

/* ipad portrait */

@media (min-device-width: 768px) and (max-device-width: 1080px) and (orientation: portrait) {
  .containerMenu_block {
    margin-left: 3rem;
  }

  .Patient_block .Image {
    width: 65%;
  }

  .circuit_block .Image {
    width: 40%;
  }

  .logo svg {
    width: 77%;
    margin-left: 17%;
    margin-top: 0.5rem;
  }

  #logoPrincipale {
    fill: unset;
  }

  .slider-item {
    padding: 0;
  }
}

/* Ipads landscape  */
@media (min-device-width: 768px) and (max-device-width: 1080px) and (orientation: landscape) {
  .Patient_block .Image {
    width: 15%;
  }

  .circuit_block {
    overflow: scroll;
  }

  .circuit_block .Image {
    width: 30%;
  }

  .logo svg {
    width: 49%;
    margin-left: 10%;
    margin-top: 0.5rem;
  }

  .slider-item {
    padding: 0;
  }
}

@media (min-device-width: 1080px) and (max-device-width: 1266px) and (orientation: landscape) {
  .Patient_block .Image {
    width: 55%;
  }

  .circuit_block .Image {
    width: 27%;
  }

  .Patient_block {
    overflow: scroll;
  }

  .logo svg {
    width: 49%;
    margin-left: 4%;
    margin-top: 0.5rem;
  }

  .slider-container-outer {
    padding: 4em 1rem 1rem 1rem !important;
  }

  .slider-item {
    padding: 0;
  }
}

@media (min-device-width: 320px) and (max-device-width: 812px) {
  /* .contact {
    padding: 1em 1.4rem 2em 1.4em !important;
  } */

  /* .mention {
    padding: 1em 1em 2rem 1em !important;
  } */

  .Patient_block .Image {
    width: 100%;
  }

  .circuit_block .Image {
    width: 45%;
  }

  .logo svg {
    width: 100%;
    margin-left: 17%;
    margin-top: 0.5rem;
  }

  .containerMenu {
    width: 100vw;
    /* z-index: 3; */
  }

  .containerMenu_block {
    margin-top: 2rem;
    margin-left: 3rem;
  }

  #logoPrincipale {
    fill: unset;
  }

  .slider-container-outer {
    padding: 4em 1rem 1rem 1rem !important;
  }

  .slider-item {
    padding: 0;
  }

  /**/
}

@media (min-device-width: 375px) and (max-device-width: 896px) and (orientation: landscape) {
  .Patient_block .Image {
    width: 35%;
  }

  .circuit_block {
    overflow: scroll;
  }

  .Patient_block {
    overflow: scroll;
  }

  .circuit_block .Image {
    width: 30%;
  }

  #logoPrincipale {
    fill: unset;
  }

  .logo svg {
    width: 50%;
    margin-left: 10%;
    margin-top: 0.5rem;
  }

  .slider-container-outer {
    padding: 4em 1rem 1rem 1rem !important;
  }

  .containerMenu_block {
    margin-top: 0rem;
    margin-inline: auto;
  }

  .slider-item {
    padding: 0;
  }
}

/* Portrait */
@media (min-device-width: 360px) and (max-device-width: 696px) and (orientation: portrait) {
  .Patient_block .Image {
    width: 100%;
  }

  .circuit_block {
    overflow: scroll;
  }

  .circuit_block .Image {
    width: 60%;
  }

  #logoPrincipale {
    fill: unset;
  }

  .slider-container-outer {
    padding: 4em 1rem 1rem 1rem !important;
  }

  .slider-item {
    padding: 0;
  }

  .footerStyles {
    width: 90%;
  }

  .footerStyles > .rightblock {
    width: 50%;
  }

  .FooterLogo {
    width: 80%;
  }
}

/* Landscape */
/* Ipads landscape  */
@media (min-device-width: 375px) and (max-device-width: 767px) and (orientation: landscape) {
  .Patient_block .Image {
    width: 40%;
  }

  .circuit_block {
    overflow: scroll;
  }

  .Patient_block {
    overflow: scroll;
  }

  .circuit_block .Image {
    width: 40%;
  }

  .logo svg {
    width: 55%;
    margin-left: 14%;
    margin-top: 0.5rem;
  }

  #logoPrincipale {
    fill: unset;
  }

  .slider-container-outer {
    padding: 4em 1rem 1rem 1rem !important;
  }

  .slider-item {
    padding: 0;
  }

  .footerStyles {
    width: 90%;
  }

  .footerStyles > .rightblock {
    width: 50%;
  }
}

@media (max-device-width: 370px) {
  .Patient_block .Image {
    width: 50%;
  }

  .circuit_block .Image {
    width: 70%;
  }

  .logo svg {
    width: 100%;
    margin-left: 18%;
    margin-top: 0.5rem;
  }

  #logoPrincipale {
    fill: unset;
  }

  .slider-container-outer {
    padding: 4em 1rem 1rem 1rem !important;
  }

  .slider-item {
    padding: 0;
  }

  .slider-container-outer {
    padding: 2rem !important;
  }

  .containerMenu {
    width: 100vw;
  }

  .containerMenu_block {
    margin-top: 2rem;
    margin-inline: auto;
  }

  .footerStyles {
    width: 90%;
  }

  .footerStyles > .rightblock {
    width: 50%;
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-device-width: 768px) and (max-width: 1024px) {
  .lecture svg {
    width: 5%;
  }
  /* .contact {
    padding: 3rem 3rem 2rem 3em;
  } */

  /* .mention {
    padding: 2rem 4rem 0rem 1rem !important;
    height: 77vh !important;
  } */

  /* CSS */
  .Patient_block {
    overflow: scroll;
  }

  .Patient_block .Image {
    width: 70%;
  }

  .circuit_block .Image {
    width: 42%;
  }

  .containerMenu {
    width: 100vw;
    /* z-index: 3; */
  }

  .containerMenu_block {
    /* margin-top: 2rem; */
    margin-inline: auto;
  }

  .footerStyles {
    width: 90%;
  }

  .footerStyles .rightblock {
    width: 50%;
  }

  .slider-container-outer {
    padding: 5em 1rem 1rem 1rem !important;
  }

  #logoPrincipale {
    fill: unset;
  }

  .logo svg {
    width: 50%;
    margin-left: 20%;
    margin-top: 0.5rem;
    display: block;
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  /* CSS */

  .containerMenu {
    width: 100vw;
  }

  .containerMenu_block {
    margin-top: 2rem;
    margin-inline: auto;
  }

  .footerStyles {
    width: 90%;
  }

  .footerStyles > .rightblock {
    width: 50%;
  }

  .slider-container-outer {
    padding: 5em 1.5rem 1rem 1.5rem !important;
  }

  #logoPrincipale {
    fill: unset;
  }

  .slider-item {
    padding: 0;
  }
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  /* .contact {
    padding: 2rem 4rem 2rem 2rem !important;
  } */

  /* .mention {
    padding: 2rem 4rem 2rem 1.5rem !important;
  } */

  /* CSS */
  .Patient_block .Image {
    width: 60%;
  }

  .containerMenu {
    width: 100vw;
  }

  .containerMenu_block {
    margin-top: 2rem;
    margin-inline: auto;
  }

  .footerStyles {
    width: 90%;
  }

  .footerStyles > .rightblock {
    width: 50%;
  }

  .slider-container-outer {
    padding: 4em 1.5rem 1rem 1.5rem !important;
  }

  /* #logoPrincipale {
    fill: unset;
  } */
}

/* @media screen and (min-width: 320px) and (max-width: 915px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
}
}

*/
.echo1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.echo::before,
.echo::after {
  content: "";
  position: absolute;
  border: 3px solid rgb(135 135 135);
  opacity: 1;
  border-radius: 50%;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
  animation: echo-animation 3s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  z-index: -1;
}

.echo::after {
  animation-delay: -1s;
}

@keyframes echo-animation {
  0% {
    width: 0;
    height: 0;
    display: block;
  }

  100% {
    width: 100%;
    height: 100%;
    display: none;
  }
}

text.link {
  cursor: pointer;
}

table {
  border-collapse: collapse;
  width: 90%;
}

tr:nth-child(1) th {
  text-align: center;
  font-family: var(--font-family-nova);
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-family: var(--font-family-nova-book);
}

.half_to_right {
  margin: 0;
  position: relative;
  /* -webkit-transform: translateX(33%);
  transform: translateX(33%); */
  vertical-align: top;
  display: inline-block;
  /* To horizontally center images and caption */
  text-align: center;
  /* The width of the container also implies margin around the images. */
  display: flex;
  align-items: center;
  gap: 1rem;
}

.half_to_right figcaption {
  display: block;
  font-size: 13px;
  margin-top: -5px;
  color: var(--background-color);
  font-weight: bold;
}

/* section:last-child {
  font-size: 13px;
} */
.contact {
  padding-top: 4rem;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: felx;

  background-image: url("./assets/images/bg-contact.jpg");
  background-size: cover;
  background-position: bottom left;

  /* padding: 2rem 4rem 2rem 4.5rem; */
  /* width: 99%; */
  height: 100vh;
  flex-direction: column;
  /* border: 2px solid black; */
  font-size: var(--font-size-18);
}

.contact .contact-flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.contact .contact-flex > div {
  padding: 2em;
}

.contact h1 {
  font-family: var(--font-family-nova);
  /* margin-bottom: 0rem; */
  margin-bottom: 1.5em;
  font-size: 2.5em;
}

.contact section {
  font-family: var(--font-family-nova-book);
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  height: 100%;
  overflow-y: auto;
  padding: 0 0.3em;
}
.contact section p {
  margin: 1em 0;
  font-size: 12pt;
}
.contact section span {
  font-family: var(--font-family-nova);
}

@media (min-width: 1920px) and (max-width: 2560px) {
  /* .contact {
    padding: 2rem 4rem 2rem 10rem;
  } */

  /* .mention {
    padding: 2rem 4rem 2rem 9.5rem !important;
    height: 90vh !important;
  } */
}

.mention {
  box-sizing: border-box;
  background-image: url("./assets/images/bg-mention-legal.jpg");
  background-size: cover;
  background-position: bottom left;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 4rem;
  padding-bottom: 1rem;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  /* flex-direction: column; */
  /* border: 2px solid black; */
  /* font-size: var(--font-size-18);
  font-family: var(--font-family-nova-book); */
}
.mention > .mention-content {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
.mention > .mention-content > svg {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: block;
}
.s1 {
  color: #0f0f0f;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 29pt;
  font-weight: bold;
  margin-bottom: 0.35em;
  /* font-family: var(--font-family-nova); */
}

.s2 {
  color: #0f0f0f;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 13pt;
}

.s3 {
  color: #ffaa7b;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 16pt;
  font-weight: bold;
}

p {
  color: black;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
  margin: 0pt;
}

.s4 {
  color: black;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

.s5 {
  color: #0f0f0f;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

.s6 {
  color: #ffaa7b;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 14pt;
  font-weight: bold;
}

.a,
a {
  color: #0562c1;

  font-style: normal;
  font-weight: normal;
  text-decoration: underline;
  font-size: 11pt;
}

.s7 {
  color: #051039;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

section b {
  color: var(--background-color);
  font-family: var(--font-family-Albert);
  font-weight: bolder;
}

/* NEW SIMULATOR CLASSES */
.s-table {
  position: relative;
  margin: auto;
}

.s-section {
  width: 80%;
}

.s-table-row {
  /* border: 1px solid #dddddd; */
  height: 3.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2%;
}

.in-section {
  padding: 0 0.3em;
  width: 90%;
  position: relative;
  margin: auto;
  height: 30%;
}

.in-section-container {
  padding: 0 0.3em;
  width: 100%;
  position: relative;
  margin: auto;
  height: 20%;
  top: 5%;
  font-size: 0.9em;
  /* Testing */
}

@media screen and (max-width: 1024px) {
  .in-section-container {
    height: 30% !important;
  }
}

.in-score {
  width: 49%;
  float: left;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1em;
}

.in-text-st {
  height: 100%;
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
}

.s-head {
  background: #000000;
  font-size: 0.9em;
  color: white;
}

.s-body {
  font-size: 0.9em;
}

.s-body-title {
  text-align: center;
}

.in-score-title {
  font-weight: 600;
}

.s-select {
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  padding: 0.5rem 3rem 0.5rem 0.5rem;
  background: var(--arrow-icon) no-repeat right 0.8em center / 1em,
    linear-gradient(to left, var(--arrow-bg) 3em, var(--select-bg) 3em);
  color: black;
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &::-ms-expand {
    display: none;
  }
  &:focus {
    outline: none;
  }
  option {
    color: inherit;
    background-color: var(--option-bg);
  }
}

.formule {
  margin-top: 10%;
  display: flex;
  height: 7%;
}

.lowPageMargins {
  margin-top: 10%;
  margin-bottom: 6em;
}

.simulator-references {
  font-size: 0.7em;
  text-align: left;
  font-style: italic;
  margin-top: 2%;
}
