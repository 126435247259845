.slider-container-outer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  background: var(--white-color-fff8f0);
  padding: 2em 5rem 2rem 5rem;
}

.slider-container {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  background: inherit;
}

.slider-item {
  width: 100%;
  position: absolute;
  padding: 0 0em;
  padding-right: 2em;
  box-sizing: border-box;
  background: inherit;
}

.pagination {
  height: 100px;
  display: flex;
  /* align-items: center; */
  width: 100%;
  margin-bottom: 1%;
  margin-top: 6%;
}

.pagination.mobile {
  justify-content: center;
}

.pagination > .left {
  width: 10%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
}

.dot {
  height: 24px;
  width: 24px;
  background-color: var(--background-color);
  border-radius: 50%;
  display: inline-block;
  z-index: 1;
  position: relative;
}

.dot.minor {
  height: 18px;
  width: 18px;
}

.dot.selected::after {
  content: "";
  top: calc(100% + 3px);
  left: 49%;
  height: 1em;
  width: 1em;

  background-color: var(--background-color);
  -webkit-mask: url("../../assets/images/bullettBottom.svg") no-repeat 50%;
  mask: url("../../assets/images/bullettBottom.svg") no-repeat 50%;

  transform: translateX(-50%);
  position: absolute;
  animation: OpacityAnimation 0.5s;
  color: var(--background-color);
}

.dot.selected::before {
  content: "";
  height: 1em;
  width: 1em;
  bottom: calc(100% + 3px);
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  animation: OpacityAnimation 0.5s;
  background-color: var(--background-color);
  -webkit-mask: url("../../assets/images/bulletTop.svg") no-repeat 50% 50%;
  mask: url("../../assets/images/bulletTop.svg") no-repeat 50% 50%;
}

.pagination > .middle {
  width: 65%;
  position: relative;
}

.pagination.mobile > .middle {
  width: 20%;
}

.pagination.haveLeft > .middle::after {
  content: "";
  position: absolute;
  width: 12%;
  height: 1px;
  top: calc(50% - 0.5px);
  background-color: var(--background-color);
  animation: WidthAnimation 1s;
}

.pagination.haveRight > .middle::before {
  content: "";
  position: absolute;
  width: 25%;
  height: 1px;
  right: 0;
  top: calc(50% - 0.5px);
  background-color: var(--background-color);
  animation: WidthAnimation 1s;
}

.pagination.mobile.haveLeft > .middle::after,
.pagination.mobile.haveRight > .middle::before {
  width: 100%;
  animation: WidthAnimationMobile 1s;
}

.pagination > .middle > .indictor {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.pagination.mobile > .middle > .indictor {
  display: none;
}

.indicator-container {
  height: 80%;
  width: 80%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  position: relative;
}

.indicator-container::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top: calc(50% - 0.5px);
  /* transform: translateY(-50%); */
  background-color: var(--background-color);
}

.pagination > .right {
  width: 25%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.pagination.mobile > .right {
  width: 60%;
}

.btn-slider {
  background: var(--background-color);
  animation: OpacityAnimation 0.5s;
  cursor: pointer;
}

.btn-slider > .btn-slider-text {
  width: 80%;
  position: absolute;
  padding: 1em;
  box-sizing: border-box;
  color: white;
  font-size: 0.8em;
  overflow: hidden;
}

.pagination > .right > .btn-slider {
  width: 100%;
  height: 80%;
  border-radius: 25px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.pagination > .right > .btn-slider:before {
  content: "\279D";
  line-height: 0;
  position: absolute;
  color: white;
  /* right is half the border radius */
  right: 13px;
  border-radius: 26px;
  top: 50%;
}

.pagination > .left > .btn-slider {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  position: relative;
}

.pagination > .left > .btn-slider:before {
  content: "\279D";
  line-height: 0;
  position: absolute;
  color: white;
  /* right is half the border radius */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
}

.article-information {
  height: 20%;
  max-height: 20%;
  min-height: 20%;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-nova);
  margin-top: -2rem;
}

.article-title {
  flex-grow: 1;
  display: grid;
  align-items: center;
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 23.5px;
  padding-right: 63px;
}

.article-subtitle {
  flex-grow: 1;
  /* display: grid; */
  align-items: center;
  margin: 0;
}

.lecture {
  height: 8%;
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 0.25em;
}

.lecture_free {
  position: absolute;
  right: 0;
  top: 7%;
}

.lecture > p {
  margin: 0 1em;
  font-size: 0.9em;
  color: var(--text-color);
  font-weight: 600;
}

.lecture-color-icon {
  fill: var(--background-color);
}

@keyframes WidthAnimation {
  from {
    width: 0%;
  }

  to {
    width: 12%;
  }
}

@keyframes WidthAnimationMobile {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

@keyframes OpacityAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
